import {backendApi} from "../../backend-api-sr"

export const AlluZincAvailability = (() =>{
    const fetchFilter = ( async (subPath) => {
        var url = '/api/v3/sr/crc-coil-az/allu-zinc-available/filter'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchIndex = ( async (subPath) => {
        var url = '/api/v3/sr/crc-coil-az/allu-zinc-available'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchSummary = ( async (subPath) => {
        var url = '/api/v3/sr/crc-coil-az/allu-zinc-available/summary'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    return {fetchFilter, fetchIndex, fetchSummary};
})()