<template>
    <v-container fluid class="mb-12">
        <v-row class = "mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled"
                                exact>
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color"
                        :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                        :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                    <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>

            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Commodity </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="commodity"
                                    :items="commodities" default="" item-value="commodity_id" item-text="descr" label="Commodity"
                                      clearable>
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Prod Code </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="prodCode"
                                    :items="prodCodes" default="" label="Prod Code" item-value="prod_code" item-text="descr"
                                      clearable>
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Warehouse </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="whId"
                                    :items="whIds" default="" label="Warehouse" item-value="wh_id" item-text="wh_id"
                                      clearable>
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Availability </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="available"
                                    :items="availables" default="" label="Availability"
                                      clearable>
                                      
                                </v-autocomplete>
                            </v-col>
                            

                            <v-col cols="12" xs="12" sm="2" md="1">
                                <v-btn class="mt-9 border-12" block style="padding: 19px;" small color="primary" elevation="2" @click="getPullData()"><v-icon class="mr-1">mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2" md="1">
                                <v-btn class="mt-9 border-12" block style="padding: 19px;" small color="success" elevation="2" >
                                    <v-icon>mdi-file-excel</v-icon>
                                    <download-excel
                                        class="text"
                                        :fetch           = "exportExcel"
                                        :fields="headersColumn"
                                        :before-generate = "startDownload"
                                        :before-finish   = "finishDownload">
                                        Export
                                    </download-excel> </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-5">
            <v-col class="col-4">
                <v-card outlined class="pa-md-5">  
                    <h6 class="cyan-text text-darken-3 m-0">Summary</h6>
                    <div id="container1" style="height: 450px; width: 100%;"></div>
                </v-card>
            </v-col>

            <v-col class="col-8">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                :headers="header"
                                :items="formattedData"
                                :loading="$store.state.overlay"
                                :item-class="tr_datatable"
                                :items-per-page="20"
                                fixed-header
                                height="375"
                                :divider="true"
                                :light="true"
                                :search="searchItem"
                            >        
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-100">
                                        <h6 class="cyan-text text-darken-3 m-0">List</h6>
                                        <v-spacer></v-spacer>
                                        <v-text-field solo style="max-width: 300px;" class="border-12" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    </div>
                                </v-toolbar>
                            </template>
                            </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {AlluZincAvailability} from "../../../backend-api/sr/crcCoilAndAz/AlluZincAvailability"
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data() {
        return{
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            breadcumbs: [
                {
                    text: 'SR',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'CRC, Coil & AZ',
                    disabled: true,
                    href: '/admin/sr',
                },
                {
                    text: 'Alu Zinc Availability',
                    disabled: true
                }
            ],
            commodity:'',
            commodities:[],
            prodCode:'',
            prodCodes:[],
            whId:'',
            whIds:[],
            available:'',
            availables:['All','Y', 'N'],
            tableVisible:false,
            header:[
                {
                    text: 'Commodity',
                    align: 'start',
                    value: 'commodity_id', 
                    align:'left'},
                { text: 'Warehouse', value: 'wh_id' , align:'left'},
                { text: 'Prod Code', value: 'prod_code' , align:'left'},
                { text: 'Description', value: 'descr' , align:'left'},
                { text: 'Weight', value: 'wgt_oh' , align:'right'},
            ],
            headersColumn:{
                'Commodity': 'commodity_id', 
                'Warehouse': 'wh_id', 
                'Prod Code': 'prod_code',
                'Description' : 'descr',
                'Weight' : 'wgt_oh'
            },
            searchItem: '',
            data:[],
            x: window.matchMedia("(max-width: 991px)"),
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', false)
        await this.getFilter();
        this.available = 'Y'
    },
    computed:{
        formattedData() {
            return this.data.map(item => ({
                ...item,
                wgt_oh: this.formatNumber(item.wgt_oh, 0, 0)
            }));
        },
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        formatNumber(number, minFrac, maxFrac) {
            var a = parseFloat(number)
            return a.toLocaleString(undefined, {
                minimumFractionDigits: minFrac,
                maximumFractionDigits: maxFrac
            });
        },
        async getFilter(event){
            const respData = await AlluZincAvailability.fetchFilter('', null, false, false, false)
            if (respData.status === 200) {
                this.commodities = respData.data.data.commodity
                this.prodCodes = respData.data.data.prodCode
                this.whIds = respData.data.data.wh_id
            }
        },
        async getPullData(event){
            var container = "container1";
            this.$store.dispatch('setOverlay', true)
            const respData = await AlluZincAvailability.fetchIndex(`?commodity_id=${this.commodity}&prod_code=${this.prodCode}&availability=${this.available}&wh_id=${this.whId}`, null, false, false, false)
            const respDataSum = await AlluZincAvailability.fetchSummary(`?commodity_id=${this.commodity}&prod_code=${this.prodCode}&availability=${this.available}&wh_id=${this.whId}`, null, false, false, false)
            if (respData.status === 200 && respDataSum.status === 200) {
                this.data = respData.data.data
                this.tableVisible = true
                this.$store.dispatch('setOverlay', false)
                const inputJson = respDataSum.data.data
                var dt = [];

                inputJson.forEach(item => {
                    dt.push({
                        "label": item.label,
                        "y": parseFloat(item.y),
                        "name": (item.label.trim() == "ALM") ? "Alumunium": (item.label.trim() == "ZN") ? "Zinc" : item.label.trim()
                    });
                });
                this.getChartPie(dt, container, "pie");
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: respData.data.data,
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                this.getChartPie([], container, "pie");
                return false
            }
        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        explodePie (e) {
            if(typeof (e.dataSeries.dataPoints[e.dataPointIndex].exploded) === "undefined" || !e.dataSeries.dataPoints[e.dataPointIndex].exploded) {
                e.dataSeries.dataPoints[e.dataPointIndex].exploded = true;
            } else {
                e.dataSeries.dataPoints[e.dataPointIndex].exploded = false;
            }
            e.chart.render();

        },
        
        getChartPie(dt1, container, type){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true,
                    yValueFormatString: "#,###",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.explodePie
                },
                data: [
                {
                    type: type,
                    indexLabel: "{label} {y} (#percent%)",
                    indexLabelFontSize: 14,
                    showInLegend: true,
                    yValueFormatString: "#,###",
                    toolTipContent: "{name}: <strong>{y} Kg</strong>",
                    // yValueFormatString: "#,###,,,.##",
                }]
            });

            chart.options.data[0].dataPoints = dt1;

            // if (this.x.matches) {

            //     for(var i = 0; i < chart.options.data.length; i++){
            //         chart.options.data[i].indexLabelFontSize = 8;
            //     }
            //     chart.render();
            // }
            chart.render();
        },


        async exportExcel(){
            const respData = await AlluZincAvailability.fetchIndex(`?commodity_id=${this.commodity}&prod_code=${this.prodCode}&availability=${this.available}`, null, false, false, false)
            return respData.data.data
        },

        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },
    watch:{},
}
</script>

<style scoped>
.tr_datatable {
    background-color: #F5F7F8 !important;
}
</style>